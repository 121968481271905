@import "~font-awesome/css/font-awesome.css";
@import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

body {
    margin: 0;
    font-family: "Inter", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* Hide scrollbars for all elements */
* {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
}

*::-webkit-scrollbar {
    width: 0px; /* Safari and Chrome */
    height: 0px; /* Safari and Chrome */
}

.custom-backdrop {
    background: black;
    opacity: 0.8 !important;
}

.ant-collapse-content-box {
    padding: 0 !important;
}

.ant-dropdown-menu {
    min-width: 140px;
}

.custom-card-background {
    background: linear-gradient(121.51deg, #ffffff 4.2%, #c6fff7 93.09%);
}

.custom-info-background {
    background: linear-gradient(119.86deg, #ffffff 1.53%, #ffffff 42.98%, #c6fff7 117.54%);
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #0e5ebc;
    border-color: #0e5ebc;
}

/* Enabling visibility of scroll for Tables */
.table-container {
    position: relative;
    overflow: hidden;
}

.table-container .react-bootstrap-table {
    position: relative;
    min-width: 100%;
    margin-bottom: 0px !important;
}

.table-container .react-bootstrap-table-container .react-bootstrap-table {
    display: block;
    overflow-x: auto; /* Enable horizontal scroll */
    overflow-y: auto; /* Enable vertical scroll  */
    scrollbar-width: auto;
    -ms-overflow-style: auto;
}

.table-container .react-bootstrap-table-container .react-bootstrap-table::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

.table-container .react-bootstrap-table-container .react-bootstrap-table::-webkit-scrollbar-thumb {
    background: #b1b1b1;
    border-radius: 16px;
}

/* Enabling visibility of vertical scroll in timeline cards */
.timeline-card-content {
    position: relative;
    overflow-y: auto;
    scrollbar-width: auto;
    -ms-overflow-style: auto;
}

.timeline-card-content::-webkit-scrollbar {
    width: 4px;
}

.timeline-card-content::-webkit-scrollbar-thumb {
    background: #b1b1b1;
    border-radius: 16px;
}

.timeline-shadow-box {
    position: absolute;
    height: 100%;
    z-index: 2;
    background: linear-gradient(90deg, rgba(247, 245, 245, 0.8) 0%, rgba(255, 255, 255, 0.1) 78.57%);
    width: 19px;
}


.timeline-container {
    position: relative;
    overflow-x: auto;
    scrollbar-width: auto;
    -ms-overflow-style: auto;
}

.timeline-container::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

.timeline-container::-webkit-scrollbar-thumb {
    background: #b1b1b1;
    border-radius: 16px;
}