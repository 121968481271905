td {
    overflow: auto;
    border: 1px solid #D8D8D8;
}

th {
    background-color: #103B6E !important;
    color: white !important;
    position: sticky;
    top: 0;
    z-index: 1;
}

tbody {
    box-shadow: 0px 2px 6px 0px rgba(225, 231, 237, 1);
}

/* th:first-child {
    border-top-left-radius: 16px;
}

th:last-child {
    border-top-right-radius: 16px;
}

tbody > tr:last-child > td:first-child {
    border-bottom-left-radius: 16px;
} */

tbody > tr:last-child {
    border: 0px;
}

.table>:not(caption)>*>* {
    background-color: transparent;
}

.table {
    margin-bottom: 0px;
}

.react-bootstrap-table {
    width: 100%;
    border-radius: 16px;
    overflow: auto;
    border-bottom: 1px solid #D8D8D8;
}

.notes-column-height {
    max-height: 2.5em; /* 3 lines, 1.5em per line */
    overflow-x: auto;
    white-space: nowrap; /* Preserve whitespace */
}

.react-bootstrap-table-pagination {
    justify-content: space-between;
    margin-left: 0;
    margin-right: 0;
    margin-top: 8px;
}

.table-fixed-header {
    height: 100%;
}

@media (min-width: 960px) {
    .react-bootstrap-table-pagination > div:first-child {
        display: flex;
        padding-bottom: 4px;
        align-items: center;
    }
}

@media (max-width: 959px) {
    .react-bootstrap-table-pagination {
        flex-direction: column-reverse;
        align-items: center;
    }

    .react-bootstrap-table-pagination > div:first-child {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
}